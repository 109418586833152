<template>
    <a-layout>
        <a-layout-sider>
            <a-anchor class="side-nav" :affix="false" @click="handleClick">
                <a-anchor-link v-for="(item,key) in parts" :key="key" :href="item.keys[0]" :title="item.name" />
            </a-anchor>
        </a-layout-sider>
        <a-layout-content>
            <h1 class="h1">GARCH模型</h1>
            <div class="result-area" id="modelInfo">
                <divider-title name="模型说明" />
                <div class="ant-table-wrapper" v-if="modelInfo">
                    <div class="ant-spin-nested-loading">
                        <div class="ant-spin-container">
                            <div class="ant-table ant-table-scroll-position-left ant-table-middle">
                                <div class="ant-table-content"><!---->
                                    <div class="ant-table-body">
                                        <table class="">
                                            <thead class="ant-table-thead">
                                                <tr>
                                                    <th :key="key" :style="{width:key===0?'100px':'auto'}" v-for="(item,key) in modelInfo[0]"><span class="ant-table-header-column"><div><span class="ant-table-column-title" v-html="item.val"></span></div></span></th>
                                                </tr>
                                            </thead>
                                            <tbody class="ant-table-tbody">
                                                <tr class="ant-table-row ant-table-row-level-0" data-row-key="0" v-for="(item,key) in modelInfo" :key="key" v-show="key>0">
                                                    <td v-for="(i,k) in item" :key="k">{{i.val}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="result-area" id="coefInfo" v-if="coefData">
                <divider-title name="系数说明" />
                <div class="ant-table-wrapper">
                    <div class="ant-spin-nested-loading">
                        <div class="ant-spin-container">
                            <div class="ant-table ant-table-scroll-position-left ant-table-middle">
                                <div class="ant-table-content">
                                    <div class="ant-table-body">
                                        <table class="">
                                            <thead class="ant-table-thead">
                                                <tr>
                                                    <th style="width:100px">系数符号</th>
                                                    <th style="width:150px">解释说明</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody class="ant-table-tbody">
                                                <tr class="ant-table-row ant-table-row-level-0">
                                                    <td>mu</td>
                                                    <td>均值模型常数项</td>
                                                    <td></td>
                                                </tr>
                                                <tr class="ant-table-row ant-table-row-level-0">
                                                    <td>ar</td>
                                                    <td>AR项系数</td>
                                                    <td></td>
                                                </tr>
                                                <tr class="ant-table-row ant-table-row-level-0">
                                                    <td>ma</td>
                                                    <td>MA项系数</td>
                                                    <td></td>
                                                </tr>
                                                <tr class="ant-table-row ant-table-row-level-0">
                                                    <td>omega</td>
                                                    <td>方差模型常数项</td>
                                                    <td></td>
                                                </tr>
                                                <tr class="ant-table-row ant-table-row-level-0">
                                                    <td>alpha</td>
                                                    <td>ARCH项系数</td>
                                                    <td></td>
                                                </tr>
                                                <tr class="ant-table-row ant-table-row-level-0">
                                                    <td>beta</td>
                                                    <td>GARCH项系数</td>
                                                    <td></td>
                                                </tr>
                                                <tr class="ant-table-row ant-table-row-level-0">
                                                    <td>gamma</td>
                                                    <td>非对称系数</td>
                                                    <td></td>
                                                </tr>
                                                <tr class="ant-table-row ant-table-row-level-0">
                                                    <td>delta</td>
                                                    <td>权重系数</td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="result-area" id="coef" v-if="coefData">
                <divider-title name="模型系数" />
                <div class="ant-table-wrapper">
                    <div class="ant-spin-nested-loading">
                        <div class="ant-spin-container">
                            <div class="ant-table ant-table-scroll-position-left ant-table-middle">
                                <div class="ant-table-content">
                                    <div class="ant-table-body">
                                        <table class="">
                                            <thead class="ant-table-thead">
                                                <tr>
                                                    <th :key="key" style="width:200px" v-for="(item,key) in coefData[0]"><span class="ant-table-header-column"><div><span class="ant-table-column-title" v-html="item.val"></span></div></span></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody class="ant-table-tbody">
                                                <tr class="ant-table-row ant-table-row-level-0" data-row-key="0" v-for="(item,key) in coefData" :key="key" v-show="key>0">
                                                    <td v-for="(i,k) in item" :key="k">{{i.val}}</td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="result-area" id="informationCriteria" v-if="informationCriteria">
                <divider-title name="信息准则" />
                <div class="ant-table-wrapper">
                    <div class="ant-spin-nested-loading">
                        <div class="ant-spin-container">
                            <div class="ant-table ant-table-scroll-position-left ant-table-middle">
                                <div class="ant-table-content">
                                    <div class="ant-table-body">
                                        <table class="">
                                            <thead class="ant-table-thead">
                                                <tr>
                                                    <th :key="key" style="width:100px" v-for="(item,key) in informationCriteria[0]"><span class="ant-table-header-column"><div><span class="ant-table-column-title" v-html="item.val"></span></div></span></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody class="ant-table-tbody">
                                                <tr class="ant-table-row ant-table-row-level-0" data-row-key="0" v-for="(item,key) in informationCriteria" :key="key" v-show="key>0">
                                                    <td v-for="(i,k) in item" :key="k">{{i.val}}</td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="result-area" id="nyblom" v-if="informationCriteria">
                <divider-title name="Hansen-Nyblom稳定性检验" />
                <div class="ant-table-wrapper">
                    <div class="ant-spin-nested-loading">
                        <div class="ant-spin-container">
                            <div class="ant-table ant-table-scroll-position-left ant-table-middle">
                                <div class="ant-table-content">
                                    <div class="ant-table-body">
                                        <table class="">
                                            <thead class="ant-table-thead">
                                                <tr>
                                                    <th :key="key" style="width:100px" v-for="(item,key) in nyblom[0]"><span class="ant-table-header-column"><div><span class="ant-table-column-title" v-html="item.val"></span></div></span></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody class="ant-table-tbody">
                                                <tr class="ant-table-row ant-table-row-level-0" data-row-key="0" v-for="(item,key) in nyblom" :key="key" v-show="key>0">
                                                    <td v-for="(i,k) in item" :key="k">{{i.val}}</td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="result-area" id="signbias" v-if="signbias">
                <divider-title name="条件方差的设定误偏检验" />
                <div class="ant-table-wrapper">
                    <div class="ant-spin-nested-loading">
                        <div class="ant-spin-container">
                            <div class="ant-table ant-table-scroll-position-left ant-table-middle">
                                <div class="ant-table-content">
                                    <div class="ant-table-body">
                                        <table class="">
                                            <thead class="ant-table-thead">
                                                <tr>
                                                    <th :key="key" style="width:100px" v-for="(item,key) in signbias[0]"><span class="ant-table-header-column"><div><span class="ant-table-column-title" v-html="item.val"></span></div></span></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody class="ant-table-tbody">
                                                <tr class="ant-table-row ant-table-row-level-0" data-row-key="0" v-for="(item,key) in signbias" :key="key" v-show="key>0">
                                                    <td v-for="(i,k) in item" :key="k">{{i.val}}</td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </a-layout-content>
    </a-layout>
</template>

<script>
import { getSessionItem } from '../../until/tools'
import DividerTitle from './components/DividerTitle.vue';
export default {
    components:{DividerTitle},
    computed:{
        result() {
            let data = getSessionItem('analysis_garch');
            return data.analysis_garch;
        },
        parts() {
            let arr = [
                {
                    name:'模型说明',
                    keys:['modelInfo'],
                },
                {
                    name:'系数说明',
                    keys:['coefInfo'],
                },
                {
                    name:'模型系数',
                    keys:['coef'],
                },
                {
                    name:'信息准则',
                    keys:['informationCriteria'],
                },
                {
                    name:'Hansen-Nyblom稳定性检验',
                    keys:['nyblom'],

                },
                {
                    name:'条件方差的设定误偏检验',
                    keys:['signbias'],
                }
            ]
            return arr;
        },
        modelInfo() {
            if(this.result) {
                return this.result.infoData.data;
            } else {
                return null;
            }
        },
        coefData() {
            if(this.result) {
                return this.result.coefData.data;
            } else {
                return null;
            }
        },
        informationCriteria() {
            if(this.result) {
                return this.result.informationCriteria.data;
            } else {
                return null;
            }
        },
        nyblom() {
            if(this.result) {
                return this.result.nyblom.data;
            } else {
                return null;
            }
        },
        signbias() {
            if(this.result) {
                return this.result.signbias.data;
            } else {
                return null;
            }
        }

        
    },
    methods:{
        handleClick(e,link) {
            e.preventDefault();
            var srcolls = document.getElementById(link.href);
            srcolls.scrollIntoView({block: 'start', behavior: 'smooth'});
        },
    }
}
</script>